import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

// this page should never render. see gatsby-node.js where we add redirect to the first guide section
const IndexPage = () => (
  <Layout>
    <SEO
      title="Community Rating System (CRS) Software and Resources for Larger Flood Insurance Discounts"
      description="Forerunner is an all-in-one tool to manage your Community Rating System (CRS) program. Save time, increase resilience, and reduce insurance premiums with our easy-to-use software for you and your community."
    />
  </Layout>
);

export default IndexPage;
